#news
	.news-content
		background-color light_grey
		padding-bottom 3rem

		.news-top-container
			padding top_padding side_padding 0 side_padding
			
			img
				width 100%
				border 1px solid gray
				margin-bottom 2rem
				
			.news-link
				border-bottom 1px solid black
				padding-bottom 1rem
				margin-top top_padding
				
				@extends .subtitle
			
			.news-title
				background-color light_grey
				@extends .subtitle
				padding-bottom 0.8rem
				position relative
				
				p
					max-width calc(100%-3rem)

			.news-title:hover
				svg
					stroke-width 3
						
			svg.arrow-link
				height 1.6rem
				width 3rem
				position absolute
				right 0.5rem
				top 0.2rem
				
				+media(mobile)
					right 0rem
				
			.news-content a:hover
				svg.arrow-download
					stroke-width 3
					
			.news-description
				padding-bottom 0.5rem
				
				a:link
					border-bottom 1px solid black
				a:hover
					border-bottom 2px solid black
					font-weight bold
			
			hr
				border 0
				border-bottom 1px solid black
				
			.news-description-default
				text-align center
				padding-bottom 2rem
				
			.img-default
				border none
				