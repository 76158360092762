#site-header
	
	background-color white
	
	margin-right auto
	margin-left auto
	position relative	
	
	#intro-homepage
		
		height 100vh
		padding 0 side_padding 0 side_padding
		position relative
		
		
		//linear_gradient(120deg, castori, castori 16.66%, lupetti 16.66%, lupetti 33.33%, esploratori 33.33%, esploratori 50%, pionieri 50%, pionieri 66.666%, rover 66.66%, rover 83.33%, sezione 83.33%, sezione)
		linear_gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, castori 50%, castori 58.333%, lupetti 58.333%, lupetti 66.66%, esploratori 66.66%, esploratori 75%, pionieri 75%, pionieri 83.333%, rover 83.333%, rover 91.666%, sezione 91.666%, sezione)
		
		h1
			line-height 98%
			color black
			
		#logo-header
			background-image url('/assets/img/logo-sezione-quadrato.svg')
			background-size cover
			display block
			position relative
			top side_padding
			
		nav
			ul
				li a
					border-bottom 0.1rem solid black
					padding-bottom 0.3rem
					box-sizing border-box
					
					&:hover
						font-weight bold
						border-width 0.3rem
						
				li[alerts]::before
					content attr(alerts)
					text-align center
					display inline-block
					position relative
					right 1rem
					width 2rem
					height 2rem
					font-size fs_subtitle
					border 2px solid black
					border-radius 50%
						
						
				li.active
					font-weight bold
					a
						border-bottom 0.2rem solid black					
				
				li + li
					margin-top 0.5rem


	@media (orientation: portrait)
	
		#intro-homepage
	
			#logo-header
				width 30vw
				height 30vw
				border-radius 2vw
				//display none

			
			h1
				font-size 12vw
				
			nav
				text-align right
				font-size 8vw
				text-transform lowercase
				position absolute
				bottom side_padding
				right side_padding
				
				li
					height 10vw
					
				li[alerts]::before
					font-size 5vw !important
					width 6vw !important
					height 6vw !important
					border 5px solid black

				
				li + li
					margin-top 0.5rem
							
		
	@media (orientation: landscape)
	
		#intro-homepage
	
			#logo-header
				width 30vh
				height 30vh
				border-radius 2vh
				//display none
				
			h1
				font-size 12vh
			
			nav
				text-align right
				font-size 8vh
				text-transform lowercase
				position absolute
				bottom side_padding
				right side_padding
				
				li
					height 10vh
					
				li[alerts]::before
					font-size 5vh !important
					width 6vh !important
					height 6vh !important
					border 5px solid black
				
				li + li
					margin-top 0.5rem
					
	+media(mobile)
		nav
			margin-bottom side_padding*2
	
	@media (min-width: 940px)	// desktop
	
		#intro-homepage
			
			height 8rem
	
			#logo-header
				logo_size = 6rem
				width logo_size
				height logo_size
				border-radius 0.4rem
				display inline-block
				
			h1
				font-size 2.1rem
				display inline-block
				margin-top 0rem
				margin-bottom 0rem
				position relative
				top 0.8rem
				left 0.3rem
		
			nav
				font-size 1.1rem
				
				ul
			
					li
						height auto
						display inline-block
						text-align center
						
						a
							padding-bottom 0.1rem
						
							&:hover
								font-weight bold
								border-width 0.2rem
							// account for the extra space in bold in the before hidden
							&::before
								content attr(title)
								display block
								font-weight bold
								height 0
								overflow hidden
								visibility hidden
					
					li[alerts]::before
						font-size fs_tag !important
						width 1rem !important
						height 1rem !important
						right -1.5rem
						border 1.5px solid black
								
					li + li
						margin-left 0.5rem