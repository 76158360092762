// variables
top_padding = 3rem
bottom_padding = 2rem

.module
	border-top 3px solid black
	position relative
	display inline-block
	width 100%
	
	// stretch the module for full screen on mobile
	/*
	+media(mobile)
		min-height 100vh
	*/
	//background-color light_grey
	
	header
		//linear_gradient(135deg, rgba(0,0,0,0), rgba(0,0,0,0) 75%, castori 75%, castori 79.166%, lupetti 79.166%, lupetti 83.333%, esploratori 83.333%, esploratori 87.5%, pionieri 87.5%, pionieri 91.666%, rover 91.666%, rover 95.833%, sezione 95.833%, sezione)
		padding 1.2rem side_padding 0 side_padding
		background-color white
		
		div
			width 39px
			border-bottom 3px solid black
			margin-bottom 2.2rem
			display none
		img
			width auto
			height 1.4rem
			
		img, h2
			display inline-block
			
		h2
			@extends .module-title
			margin-top 0.6rem
			position relative
			left .5rem
			
	header:after
		content ""
		clear both
		display table
			
.module-content::before
	content ""
	width 100%
	height 8px
	position absolute
	background linear-gradient(to bottom, rgba(0, 0, 0, 0.16) 0%, transparent 100%)
	z-index 2