.circolari.pages
	
	.module-content > div
		@extends .box-wrapper
		&.empty
			padding-bottom 1rem
		
		h2
			padding-bottom 1rem
			border-bottom 1px solid black
			
		ul	
						
			li
				display block
				position relative
				padding-bottom 1rem
				border-bottom 1px solid black
				
				a:after
					content ""
					clear both
					display table
				
				h3
					margin-bottom 0
					
					+media(mobile)
						padding-right 3rem
					
				svg.arrow-download
					text-align right
					position absolute
					right 0
					top 0
					height 6rem
					width 2rem
				
					+media(mobile)
						height 4rem
						width 2rem
				p
					padding-right 3rem
					
				.circolari-date
					margin-bottom 1rem
					
			li:hover
				svg.arrow-download
					stroke-width 3
					
				box-sizing border-box
				border-bottom 2px solid black
				padding-bottom calc(1rem - 1px)