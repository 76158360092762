.album.pages
	
	.page-header
		padding-top 1.5rem
		
		.back-arrow
			display inline-block
		
		h2
			display inline
			position relative
			top -0.2rem
			
		.page-title
			display block
			margin 0
			padding-bottom 1rem
			padding-top 1rem
			position static
			
	.module-content
		background-color white
		padding-bottom 2rem
		
		#metadata
			@extends .wrapper
			
		#gallery
			padding-top 1rem
			
			@extends .wrapper
			
			ul
				li
					margin-bottom 0.5rem
					img
						width 100%
			
			#bottom-nav-back.page-header
				margin-top 2rem
				border-top none
						

	+media(tablet-v)
		
		.page-header
			
			.page-title
				display block
				padding-left 2.5rem
				position relative
				
		.module-content
			
			#metadata
				@extends .wrapper
				
			#gallery
				padding-top 1rem
				
				@extends .wrapper
				
				ul
					li
						padding-right 1rem
						padding-left 1rem
						padding-bottom 1rem
						
						img
							width 100%
							
