.pages
	.castori
		background-color castori
	.lupetti
		background-color lupetti
	.esploratori
		background-color esploratori
	.pionieri
		background-color pionieri
	.rover
		background-color rover
	.sezione
		background-color sezione
	
.box-wrapper
	padding 2rem side_padding 3rem side_padding	

.page-title
	@extends .page-title-font
	margin-top 1rem
	display box

.page-header
	padding-bottom 0rem
	padding-left side_padding
	padding-right side_padding
	position relative
	background-color white
	border-top 3px solid black
	padding-top 0.5rem
	//display none
	
	
	+media(tablet-h)
		padding-top 1rem
		padding-bottom 0.8rem
	
	+media(desktop)
		display auto

	.back-arrow
		margin-right 1rem
		display none
		
		svg.arrow-link
			height 1.6rem
			transform rotate(180deg)
			left side_padding
			position static
		
		+media(tablet-v)
			display inline-block
			
	.back-arrow svg.arrow-link:hover
		stroke-width 3
			
	.page-title
		display inline-block
		margin-top 1rem
		position relative
		left 0.5rem

	.page-icon
		width auto
		height 1.4rem
		// position absolute
		// right side_padding	
		// top 3.9rem
			
.module-content
	position relative

.content-background-style
	background-color: white
	border-top: 2px solid black
	@extends .box-wrapper