#avvisi
	.module-content
		background-color light_grey
		overflow auto
		
		article
			margin-left side_padding
			margin-right side_padding
			border-left 1rem solid
			background-color white
			box-shadow 0 3px 6px rgba(0, 0, 0, 0.16)
			padding 0.8rem 0.8rem 1rem 1rem
			
			&.castori
				border-color castori
			&.lupetti
				border-color lupetti
			&.esploratori
				border-color esploratori
			&.pionieri
				border-color pionieri
			&.rover
				border-color rover
			&.sezione
				border-color sezione
					
			h3
				@extends .tag
				color gray
				margin-bottom 0.5rem
				margin-top 0
			h4
				@extends .base-text
				font-weight bold
				margin-bottom 0
				margin-top 0px
			
			&:first-child
				margin-top 3rem
			&:last-child
				margin-bottom 3rem
		
		article + article
			margin-top 2rem