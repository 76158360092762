@import url('https://fonts.googleapis.com/css?family=Roboto');


// rem value for the whole website

fsm = 16px	// font size mobile
fsd = 20px	// font size desktop

html
	font-size fsm
		
	+media(tablet-v)
		font-size fsd
		
body
	font-family 'Arial'
	color black

	
// font sizes
fs_h1 = 3rem
fs_title = 1.7143rem	// 24px mobile
fs_subtitle = 1.5714rem	// 22px mobile
fs_text = 1rem 			// 14px mobile
fs_tag = 0.8rem		// 10px	mobile

// font weights
bold = 700
regular = 100

a:link, a:hover, a:visited, a:focus
	text-decoration none
	color black

.module-title	// 24px
	font-size fs_title
	font-weight bold
	text-transform uppercase

.tag
	font-size fs_tag
	text-transform capitalize
	
.subtitle
	font-size fs_subtitle
	font-weight bold

.base-text
	font-size fs_text
	font-weight regular
	
.page-title-font
	font-size fs_title
	font-weight bold
	text-transform uppercase
	
	/*
	+media(mobile)
		font-size 10vw
	*/