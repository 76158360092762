html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.25;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  background-color: #f3eee7;
}
body {
  margin-right: auto;
  margin-left: auto;
  max-width: 950px;
}
.wrapper,
.album.pages .module-content #metadata,
.album.pages .module-content #gallery {
  margin-right: auto;
  margin-left: auto;
/*
	+media(tablet-h)
		display flex
		flex-wrap wrap
		align-items stretch
		align-content stretch 
		
		.module
			width 50%
		
		.module#circolari
			width 100%
			
			.module-content
				display flex
				flex-wrap wrap
				align-content stretch
				align-items stretch
			
				a
					width 50%
	*/
}
@import url("https://fonts.googleapis.com/css?family=Roboto");
html {
  font-size: 16px;
}
@media (min-width: 600px) {
  html {
    font-size: 20px;
  }
}
body {
  font-family: 'Arial';
  color: #1e1e1e;
}
a:link,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: #1e1e1e;
}
.module-title,
.module header h2,
footer .footer-container #contacts-link {
  font-size: 1.7143rem;
  font-weight: 700;
  text-transform: uppercase;
}
.tag,
#avvisi .module-content article h3,
#circolari article .circolari-branca,
#libreria .libreria-type {
  font-size: 0.8rem;
  text-transform: capitalize;
}
.subtitle,
#circolari article h2,
#news .news-content .news-top-container .news-link,
#news .news-content .news-top-container .news-title {
  font-size: 1.5714rem;
  font-weight: 700;
}
.base-text,
#avvisi .module-content article h4 {
  font-size: 1rem;
  font-weight: 100;
}
.page-title-font,
.page-title {
  font-size: 1.7143rem;
  font-weight: 700;
  text-transform: uppercase;
/*
	+media(mobile)
		font-size 10vw
	*/
}
.module {
  border-top: 3px solid #1e1e1e;
  position: relative;
  display: inline-block;
  width: 100%;
/*
	+media(mobile)
		min-height 100vh
	*/
}
.module header {
  padding: 1.2rem 1rem 0 1rem;
  background-color: #fff;
}
.module header div {
  width: 39px;
  border-bottom: 3px solid #1e1e1e;
  margin-bottom: 2.2rem;
  display: none;
}
.module header img {
  width: auto;
  height: 1.4rem;
}
.module header img,
.module header h2 {
  display: inline-block;
}
.module header h2 {
  margin-top: 0.6rem;
  position: relative;
  left: 0.5rem;
}
.module header:after {
  content: "";
  clear: both;
  display: table;
}
.module-content::before {
  content: "";
  width: 100%;
  height: 8px;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0,0,0,0.16) 0%, transparent 100%);
  z-index: 2;
}
.pages .castori {
  background-color: #d6d6d6;
}
.pages .lupetti {
  background-color: #b2cdff;
}
.pages .esploratori {
  background-color: #fccb8a;
}
.pages .pionieri {
  background-color: #ff627a;
}
.pages .rover {
  background-color: #73f360;
}
.pages .sezione {
  background-color: #ffe800;
}
.box-wrapper,
.content-background-style,
.circolari.pages .module-content > div,
.gallery-year.pages .module-content ul li {
  padding: 2rem 1rem 3rem 1rem;
}
.page-title {
  margin-top: 1rem;
  display: box;
}
.page-header {
  padding-bottom: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  background-color: #fff;
  border-top: 3px solid #1e1e1e;
  padding-top: 0.5rem;
}
@media (min-width: 900px) {
  .page-header {
    padding-top: 1rem;
    padding-bottom: 0.8rem;
  }
}
@media (min-width: 1299px) {
  .page-header {
    display: auto;
  }
}
.page-header .back-arrow {
  margin-right: 1rem;
  display: none;
}
.page-header .back-arrow svg.arrow-link {
  height: 1.6rem;
  transform: rotate(180deg);
  left: 1rem;
  position: static;
}
@media (min-width: 600px) {
  .page-header .back-arrow {
    display: inline-block;
  }
}
.page-header .back-arrow svg.arrow-link:hover {
  stroke-width: 3;
}
.page-header .page-title {
  display: inline-block;
  margin-top: 1rem;
  position: relative;
  left: 0.5rem;
}
.page-header .page-icon {
  width: auto;
  height: 1.4rem;
}
.module-content {
  position: relative;
}
.content-background-style {
  background-color: #fff;
  border-top: 2px solid #1e1e1e;
}
#avvisi .module-content {
  background-color: #f9f9f9;
  overflow: auto;
}
#avvisi .module-content article {
  margin-left: 1rem;
  margin-right: 1rem;
  border-left: 1rem solid;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  padding: 0.8rem 0.8rem 1rem 1rem;
}
#avvisi .module-content article.castori {
  border-color: #d6d6d6;
}
#avvisi .module-content article.lupetti {
  border-color: #b2cdff;
}
#avvisi .module-content article.esploratori {
  border-color: #fccb8a;
}
#avvisi .module-content article.pionieri {
  border-color: #ff627a;
}
#avvisi .module-content article.rover {
  border-color: #73f360;
}
#avvisi .module-content article.sezione {
  border-color: #ffe800;
}
#avvisi .module-content article h3 {
  color: #acacac;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
#avvisi .module-content article h4 {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0px;
}
#avvisi .module-content article:first-child {
  margin-top: 3rem;
}
#avvisi .module-content article:last-child {
  margin-bottom: 3rem;
}
#avvisi .module-content article + article {
  margin-top: 2rem;
}
#circolari article {
  padding: 3rem 1rem 1rem 1rem;
  position: relative;
}
#circolari article.castori {
  background-color: #d6d6d6;
}
#circolari article.lupetti {
  background-color: #b2cdff;
}
#circolari article.esploratori {
  background-color: #fccb8a;
}
#circolari article.pionieri {
  background-color: #ff627a;
}
#circolari article.rover {
  background-color: #73f360;
}
#circolari article.sezione {
  background-color: #ffe800;
}
#circolari article h2 {
  padding-right: 3rem;
}
#circolari article .circolari-description {
  margin-bottom: 3rem;
}
#circolari article .download-arrow {
  position: absolute;
  top: 3rem;
  right: 1.3rem;
}
#circolari article .download-arrow svg.arrow-download {
  height: 6rem;
  width: 2rem;
}
@media (max-width: 599px) {
  #circolari article .download-arrow svg.arrow-download {
    height: 4rem;
    width: 2rem;
  }
}
#circolari article .circolari-branca {
  border-top: 1px solid #1e1e1e;
  padding-top: 0.3rem;
}
#galleria .module-content {
  background-color: #f9f9f9;
}
#galleria .module-content .gallery-container {
  padding: 3rem 1rem 0 1rem;
}
#galleria .module-content .gallery-container img {
  width: 100%;
  height: auto;
}
#libreria .library-bollettini,
#libreria .library-topic {
  padding: 3rem 1rem 1rem 1rem;
}
#libreria .libreria-type {
  border-top: 1px solid #1e1e1e;
  padding-top: 0.3rem;
}
#montascio .montascio-container {
  background-color: #f9f9f9;
  padding: 3rem 1rem 0 1rem;
}
#montascio .montascio-container img {
  width: 100%;
  height: auto;
}
#montascio .montascio-container .montascio-description {
  margin-top: 2rem;
}
#news .news-content {
  background-color: #f9f9f9;
  padding-bottom: 3rem;
}
#news .news-content .news-top-container {
  padding: 3rem 1rem 0 1rem;
}
#news .news-content .news-top-container img {
  width: 100%;
  border: 1px solid #acacac;
  margin-bottom: 2rem;
}
#news .news-content .news-top-container .news-link {
  border-bottom: 1px solid #1e1e1e;
  padding-bottom: 1rem;
  margin-top: 3rem;
}
#news .news-content .news-top-container .news-title {
  background-color: #f9f9f9;
  padding-bottom: 0.8rem;
  position: relative;
}
#news .news-content .news-top-container .news-title p {
  max-width: calc(100% -3rem);
}
#news .news-content .news-top-container .news-title:hover svg {
  stroke-width: 3;
}
#news .news-content .news-top-container svg.arrow-link {
  height: 1.6rem;
  width: 3rem;
  position: absolute;
  right: 0.5rem;
  top: 0.2rem;
}
@media (max-width: 599px) {
  #news .news-content .news-top-container svg.arrow-link {
    right: 0rem;
  }
}
#news .news-content .news-top-container .news-content a:hover svg.arrow-download {
  stroke-width: 3;
}
#news .news-content .news-top-container .news-description {
  padding-bottom: 0.5rem;
}
#news .news-content .news-top-container .news-description a:link {
  border-bottom: 1px solid #1e1e1e;
}
#news .news-content .news-top-container .news-description a:hover {
  border-bottom: 2px solid #1e1e1e;
  font-weight: 700;
}
#news .news-content .news-top-container hr {
  border: 0;
  border-bottom: 1px solid #1e1e1e;
}
#news .news-content .news-top-container .news-description-default {
  text-align: center;
  padding-bottom: 2rem;
}
#news .news-content .news-top-container .img-default {
  border: none;
}
.album.pages .page-header {
  padding-top: 1.5rem;
}
.album.pages .page-header .back-arrow {
  display: inline-block;
}
.album.pages .page-header h2 {
  display: inline;
  position: relative;
  top: -0.2rem;
}
.album.pages .page-header .page-title {
  display: block;
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: static;
}
.album.pages .module-content {
  background-color: #fff;
  padding-bottom: 2rem;
}
.album.pages .module-content #gallery {
  padding-top: 1rem;
}
.album.pages .module-content #gallery ul li {
  margin-bottom: 0.5rem;
}
.album.pages .module-content #gallery ul li img {
  width: 100%;
}
.album.pages .module-content #gallery #bottom-nav-back.page-header {
  margin-top: 2rem;
  border-top: none;
}
@media (min-width: 600px) {
  .album.pages .page-header .page-title {
    display: block;
    padding-left: 2.5rem;
    position: relative;
  }
  .album.pages .module-content #gallery {
    padding-top: 1rem;
  }
  .album.pages .module-content #gallery ul li {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .album.pages .module-content #gallery ul li img {
    width: 100%;
  }
}
.circolari.pages .module-content > div.empty {
  padding-bottom: 1rem;
}
.circolari.pages .module-content > div h2 {
  padding-bottom: 1rem;
  border-bottom: 1px solid #1e1e1e;
}
.circolari.pages .module-content > div ul li {
  display: block;
  position: relative;
  padding-bottom: 1rem;
  border-bottom: 1px solid #1e1e1e;
}
.circolari.pages .module-content > div ul li a:after {
  content: "";
  clear: both;
  display: table;
}
.circolari.pages .module-content > div ul li h3 {
  margin-bottom: 0;
}
@media (max-width: 599px) {
  .circolari.pages .module-content > div ul li h3 {
    padding-right: 3rem;
  }
}
.circolari.pages .module-content > div ul li svg.arrow-download {
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  height: 6rem;
  width: 2rem;
}
@media (max-width: 599px) {
  .circolari.pages .module-content > div ul li svg.arrow-download {
    height: 4rem;
    width: 2rem;
  }
}
.circolari.pages .module-content > div ul li p {
  padding-right: 3rem;
}
.circolari.pages .module-content > div ul li .circolari-date {
  margin-bottom: 1rem;
}
.circolari.pages .module-content > div ul li:hover {
  box-sizing: border-box;
  border-bottom: 2px solid #1e1e1e;
  padding-bottom: calc(1rem - 1px);
}
.circolari.pages .module-content > div ul li:hover svg.arrow-download {
  stroke-width: 3;
}
.contacts #contacts li h2 {
  margin: 0 0 0.3rem 0;
}
.contacts #contacts ul.sezione li + li {
  margin-top: 2rem;
}
.contacts #contacts ul.branche .cb {
  margin-top: 0.5rem;
}
.contacts #contacts ul.branche .tel {
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-bottom: 0.05rem solid #1e1e1e;
}
.contacts #map iframe {
  width: 100%;
  height: 60vh;
}
@media (min-width: 900px) {
  .contacts #map iframe {
    height: 800px;
  }
}
.gallery-year.pages .page-header .back-arrow {
  display: inline;
}
.gallery-year.pages .module-content ul li h2 {
  border-bottom: 1px solid #1e1e1e;
}
.gallery-year.pages .module-content ul li ul a {
  display: block;
}
.gallery-year.pages .module-content ul li ul a li {
  padding: 1rem;
  background-color: #fff;
  position: relative;
}
.gallery-year.pages .module-content ul li ul a li:after {
  content: "";
  display: block;
  padding-bottom: calc(4.1 / 4 * 100%);
}
.gallery-year.pages .module-content ul li ul a li div {
  width: calc(100% -2rem);
  background-size: cover !important;
  background-position: center center;
  position: absolute;
}
.gallery-year.pages .module-content ul li ul a li div:after {
  content: "";
  display: block;
  padding-bottom: calc(2.9 / 3.5 * 100%);
}
.gallery-year.pages .module-content ul li ul a li p {
  font-weight: 100;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: calc(2.9 / 3.5 * 100%);
}
.gallery-year.pages .module-content ul li ul a li:hover {
  box-shadow: 2px 2px 10px #808080;
  transform: translate(0, -1px);
}
.gallery-year.pages .module-content ul li ul a + a {
  margin-top: 1rem;
}
@media (min-width: 600px) {
  .gallery-year.pages .module-content ul li ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
  }
  .gallery-year.pages .module-content ul li ul a {
    width: 48%;
  }
  .gallery-year.pages .module-content ul li ul a li p {
    font-size: 16px;
  }
  .gallery-year.pages .module-content ul li ul a + a {
    margin-top: 0;
  }
}
@media (min-width: 900px) {
  .gallery-year.pages .module-content ul li ul a li p {
    font-size: 1rem;
  }
}
.gallery.pages .module-content {
  background-color: #fff;
  padding-bottom: 2rem;
}
.gallery.pages .module-content ul {
  padding: 1rem;
}
.gallery.pages .module-content ul li {
  font-size: 2rem;
  margin-top: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #1e1e1e;
  text-align: center;
  position: relative;
}
.gallery.pages .module-content ul li .year-arrow {
  display: none;
}
@media (min-width: 600px) {
  .gallery.pages .module-content ul li {
    font-size: 3rem;
    text-align: left;
  }
  .gallery.pages .module-content ul li .year-arrow {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: scale(1.5);
  }
  .gallery.pages .module-content ul li:hover svg.arrow-link {
    stroke-width: 3;
  }
}
.homepage h1.page-title {
  display: none;
}
.homepage .news {
  text-align: center;
  margin-bottom: 5rem;
}
.homepage .news article header h1 {
  display: none;
}
.homepage .news img {
  width: 100%;
  max-width: 600px;
}
.homepage .news #extra-box {
  margin-top: 1rem;
}
.homepage .alerts,
.homepage .circolari {
  margin-bottom: 5rem;
}
.homepage .alerts .alert {
  padding: 0.5rem;
}
.homepage .alerts .alert p {
  margin: 0;
}
.homepage .alerts .alert + .alert {
  margin-top: 1rem;
}
.homepage .circolari .circolare {
  padding: 0.5rem;
}
.homepage .circolari .circolare h1 {
  margin: 0 0 0.5rem 0;
}
.homepage .circolari .circolare h1 a:link {
  text-decoration: none;
}
.homepage .circolari .circolare p {
  margin: 0;
}
.homepage .circolari .circolare + .circolare {
  margin-top: 1rem;
}
.library #articoli {
  margin-top: 2rem;
}
.library #articoli ul {
  display: float;
}
.library #articoli ul > li {
  display: inline-block;
  width: 100%;
}
@media (min-width: 600px) {
  .library #articoli ul > li {
    width: 49%;
  }
}
@media (min-width: 900px) {
  .library #articoli ul > li {
    width: 24%;
  }
}
footer {
  background-color: #b3aea7;
}
footer .footer-container {
  padding: 9rem 1rem 2rem 1rem;
}
@media (min-width: 900px) {
  footer .footer-container {
    padding-top: 3rem;
  }
}
footer .footer-container p#footer-title {
  font-size: 1.7143rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
footer .footer-container #contacts-link {
  padding-top: 4rem;
  position: relative;
}
footer .footer-container #contacts-link svg.arrow-link {
  position: absolute;
  right: 0 !important;
  bottom: 1rem;
  height: 1.6rem;
}
footer .footer-container #contacts-link:hover svg.arrow-link {
  stroke-width: 3;
}
footer .footer-container p#copyright {
  font-size: 0.8rem;
  font-weight: 100;
  border: none;
  border-top: 1px solid #1e1e1e;
  padding-bottom: 0;
  padding-top: 1rem;
  margin-top: 3.5rem;
}
#site-header {
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
#site-header #intro-homepage {
  height: 100vh;
  padding: 0 1rem 0 1rem;
  position: relative;
  background-color: rgba(0,0,0,0);
  background-image: -webkit-linear-gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, #d6d6d6 50%, #d6d6d6 58.333%, #b2cdff 58.333%, #b2cdff 66.66%, #fccb8a 66.66%, #fccb8a 75%, #ff627a 75%, #ff627a 83.333%, #73f360 83.333%, #73f360 91.666%, #ffe800 91.666%, #ffe800);
  background-image: -moz-linear-gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, #d6d6d6 50%, #d6d6d6 58.333%, #b2cdff 58.333%, #b2cdff 66.66%, #fccb8a 66.66%, #fccb8a 75%, #ff627a 75%, #ff627a 83.333%, #73f360 83.333%, #73f360 91.666%, #ffe800 91.666%, #ffe800);
  background-image: -ms-linear-gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, #d6d6d6 50%, #d6d6d6 58.333%, #b2cdff 58.333%, #b2cdff 66.66%, #fccb8a 66.66%, #fccb8a 75%, #ff627a 75%, #ff627a 83.333%, #73f360 83.333%, #73f360 91.666%, #ffe800 91.666%, #ffe800);
  background-image: -o-linear-gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, #d6d6d6 50%, #d6d6d6 58.333%, #b2cdff 58.333%, #b2cdff 66.66%, #fccb8a 66.66%, #fccb8a 75%, #ff627a 75%, #ff627a 83.333%, #73f360 83.333%, #73f360 91.666%, #ffe800 91.666%, #ffe800);
  background-image: linear-gradient(120deg, rgba(0,0,0,0), rgba(0,0,0,0) 50%, #d6d6d6 50%, #d6d6d6 58.333%, #b2cdff 58.333%, #b2cdff 66.66%, #fccb8a 66.66%, #fccb8a 75%, #ff627a 75%, #ff627a 83.333%, #73f360 83.333%, #73f360 91.666%, #ffe800 91.666%, #ffe800);
}
#site-header #intro-homepage h1 {
  line-height: 98%;
  color: #1e1e1e;
}
#site-header #intro-homepage #logo-header {
  background-image: url("/assets/img/logo-sezione-quadrato.svg");
  background-size: cover;
  display: block;
  position: relative;
  top: 1rem;
}
#site-header #intro-homepage nav ul li a {
  border-bottom: 0.1rem solid #1e1e1e;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
}
#site-header #intro-homepage nav ul li a:hover {
  font-weight: 700;
  border-width: 0.3rem;
}
#site-header #intro-homepage nav ul li[alerts]::before {
  content: attr(alerts);
  text-align: center;
  display: inline-block;
  position: relative;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.5714rem;
  border: 2px solid #1e1e1e;
  border-radius: 50%;
}
#site-header #intro-homepage nav ul li.active {
  font-weight: 700;
}
#site-header #intro-homepage nav ul li.active a {
  border-bottom: 0.2rem solid #1e1e1e;
}
#site-header #intro-homepage nav ul li + li {
  margin-top: 0.5rem;
}
@media (orientation: portrait) {
  #site-header #intro-homepage #logo-header {
    width: 30vw;
    height: 30vw;
    border-radius: 2vw;
  }
  #site-header #intro-homepage h1 {
    font-size: 12vw;
  }
  #site-header #intro-homepage nav {
    text-align: right;
    font-size: 8vw;
    text-transform: lowercase;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  #site-header #intro-homepage nav li {
    height: 10vw;
  }
  #site-header #intro-homepage nav li[alerts]::before {
    font-size: 5vw !important;
    width: 6vw !important;
    height: 6vw !important;
    border: 5px solid #1e1e1e;
  }
  #site-header #intro-homepage nav li + li {
    margin-top: 0.5rem;
  }
}
@media (orientation: landscape) {
  #site-header #intro-homepage #logo-header {
    width: 30vh;
    height: 30vh;
    border-radius: 2vh;
  }
  #site-header #intro-homepage h1 {
    font-size: 12vh;
  }
  #site-header #intro-homepage nav {
    text-align: right;
    font-size: 8vh;
    text-transform: lowercase;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
  #site-header #intro-homepage nav li {
    height: 10vh;
  }
  #site-header #intro-homepage nav li[alerts]::before {
    font-size: 5vh !important;
    width: 6vh !important;
    height: 6vh !important;
    border: 5px solid #1e1e1e;
  }
  #site-header #intro-homepage nav li + li {
    margin-top: 0.5rem;
  }
}
@media (max-width: 599px) {
  #site-header nav {
    margin-bottom: 2rem;
  }
}
@media (min-width: 940px) {
  #site-header #intro-homepage {
    height: 8rem;
  }
  #site-header #intro-homepage #logo-header {
    width: 6rem;
    height: 6rem;
    border-radius: 0.4rem;
    display: inline-block;
  }
  #site-header #intro-homepage h1 {
    font-size: 2.1rem;
    display: inline-block;
    margin-top: 0rem;
    margin-bottom: 0rem;
    position: relative;
    top: 0.8rem;
    left: 0.3rem;
  }
  #site-header #intro-homepage nav {
    font-size: 1.1rem;
  }
  #site-header #intro-homepage nav ul li {
    height: auto;
    display: inline-block;
    text-align: center;
  }
  #site-header #intro-homepage nav ul li a {
    padding-bottom: 0.1rem;
  }
  #site-header #intro-homepage nav ul li a:hover {
    font-weight: 700;
    border-width: 0.2rem;
  }
  #site-header #intro-homepage nav ul li a::before {
    content: attr(title);
    display: block;
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  #site-header #intro-homepage nav ul li[alerts]::before {
    font-size: 0.8rem !important;
    width: 1rem !important;
    height: 1rem !important;
    right: -1.5rem;
    border: 1.5px solid #1e1e1e;
  }
  #site-header #intro-homepage nav ul li + li {
    margin-left: 0.5rem;
  }
}
