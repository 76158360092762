#circolari
	article
		padding top_padding side_padding 1rem side_padding
		position relative

		&.castori
			background-color castori
		&.lupetti
			background-color lupetti
		&.esploratori
			background-color esploratori
		&.pionieri
			background-color pionieri
		&.rover
			background-color rover
		&.sezione
			background-color sezione
		
		h2
			@extends .subtitle
			padding-right 3rem
		
		.circolari-description
			margin-bottom 3rem
			
		.download-arrow
			position absolute
			top 3rem
			right side_padding+0.3rem
			
			svg.arrow-download
				height 6rem
				width 2rem
				
				+media(mobile)
					height 4rem
					width 2rem
				
		
		.circolari-branca
			border-top 1px solid black
			padding-top 0.3rem
			@extends .tag
	/*	
	+media(tablet-h)
		a
			display block
			
			article
				height 100%
	*/