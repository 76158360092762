.homepage
	h1.page-title
		display none
		
	.news
		text-align center
		
		article header h1
			display none
		img
			width 100%
			max-width 600px
			
		#extra-box
			margin-top 1rem
			
		margin-bottom 5rem
		

	.alerts, .circolari
		margin-bottom 5rem
	

	.alerts .alert
		padding 0.5rem
		
		p
			margin 0
		
		& + .alert
			margin-top 1rem
			

	.circolari .circolare
		padding 0.5rem
		
		h1
			margin 0 0 0.5rem 0
			
			a:link
				text-decoration none
		
		p
			margin 0
			
		& + .circolare
			margin-top 1rem