// definition of the breakpoints (mobile first approach -> min-width)
media_queries = {
	mobile: "(max-width: 599px)",
	tablet-v: "(min-width: 600px)",
	tablet-h: "(min-width: 900px)",
	desktop: "(min-width: 1299px)",
}

//make the mixin function
media(breakpoints)
	conditions = ()
	for breakpoint in breakpoints
		push(conditions, media_queries[breakpoint])
	conditions = join(", ", conditions)
	@media conditions
		{block}
	
width_max_site = 950px
side_padding = 1rem



body
	margin-right auto
	margin-left auto
	max-width width_max_site

.wrapper
	margin-right auto
	margin-left auto
	
	/*
	+media(tablet-h)
		display flex
		flex-wrap wrap
		align-items stretch
		align-content stretch 
		
		.module
			width 50%
		
		.module#circolari
			width 100%
			
			.module-content
				display flex
				flex-wrap wrap
				align-content stretch
				align-items stretch
			
				a
					width 50%
	*/

// animated page scroll

/*
html
	scroll-behavior smooth
	
@media screen and (prefers-reduced-motion: reduce)
	html
		scroll-behavior auto
*/
	
		