.library
	#articoli
		margin-top 2rem
					
		ul
			display float
		ul > li
			display inline-block
			width 100%
			
			+media(tablet-v)
				width 49%
			+media(tablet-h)
				width 24%
		

			