.contacts
	#contacts
		
		li h2
			margin 0 0 0.3rem 0
			

		ul.sezione			
			li + li
				margin-top 2rem
		
		ul.branche
			
			.cb
				margin-top 0.5rem

			.tel
				margin-top 0.2rem
				padding-bottom 0.2rem
				border-bottom 0.05rem solid black
		
		
			
				
	#map
		iframe
			width 100%
			height 60vh
		
		+media(tablet-h)
			iframe
				height 800px