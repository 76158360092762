.gallery-year.pages
	
	.page-header
		.back-arrow
			display inline
			
	.module-content
		
		ul
			li
				@extends .box-wrapper
				
				h2
					border-bottom 1px solid black
					
				ul
					a
						display block
						
						
						li
							padding 1rem
							background-color white
							position relative

							&:after
								content ""
								display block
								padding-bottom calc(4.1/4 * 100%)
								
							
							div
								width calc(100%-2rem)
								background-size cover !important
								background-position center center
								position absolute
								
								&:after
									content ""
									display block
									padding-bottom calc(2.9/3.5 * 100%)
									
								
							p
								font-weight regular
								text-align center
								position absolute
								left 0
								right 0
								margin-left auto
								margin-right auto
								top calc(2.9/3.5 * 100%)
								
						li:hover
							box-shadow 2px 2px 10px grey
							transform translate(0, -1px)
						
							
					a + a
						margin-top 1rem

				+media(tablet-v)
					
					ul
						display flex
						flex-wrap wrap
						justify-content space-between
						row-gap 2rem
						
						a
							width 48%
							
							li
								p
									font-size fsm
							
						a + a
							margin-top 0
							
				+media(tablet-h)
				
					ul
						a
							li
								p
									font-size 1rem
							
