footer	
	background-color footer
	
	.footer-container
		padding 9rem side_padding 2rem side_padding
		
		+media(tablet-h)
			padding-top 3rem
		
		p#footer-title
			font-size fs_title
			font-weight bold
			margin-bottom 1rem
		
		#contacts-link
			@extends .module-title
			padding-top 4rem
			position relative
			
			svg.arrow-link
				position absolute
				right 0 !important
				bottom 1rem
				height 1.6rem
		
		#contacts-link:hover
			svg.arrow-link
				stroke-width 3
				
		
		p#copyright
			font-size fs_tag
			font-weight regular
			border none
			border-top 1px solid black
			padding-bottom 0
			padding-top 1rem
			margin-top 3.5rem