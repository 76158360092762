.gallery.pages
	
	.module-content
		background-color white
		padding-bottom 2rem
		
		ul
			padding 1rem
								
			li
				font-size 2rem
				margin-top 1.5rem
				padding-bottom 0.5rem
				border-bottom 1px solid black
				text-align center
				position relative
				
				.year-arrow
					display none

			
			+media(tablet-v)
			
				li
					font-size 3rem
					text-align left
					
					.year-arrow
						display block
						position absolute
						right 1rem
						top 1rem
						transform scale(1.5)
						

				li:hover
					svg.arrow-link
						stroke-width 3